/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Sidebar from "./components/sidebar/Sidebar";
import { Outlet, useLocation } from "react-router-dom";

import { hasPermission } from "../utils/AuthorizeNavigation/authorizeNavigation2";

// import HeaderTabs from "./components/headerTabs/headerTabs";
import Unauthorize from "../pages/unauthorize/Unauthorize";
import HeaderBanner from "./components/headerBanner/HeaderBanner";

const Layout = (props) => {
  // Get the current location using React Router's useLocation hook
  const location = useLocation();

  // Extract the last segment of the pathname as routePath
  // let routePath = location.pathname.replace("/", "");

  let routePath = location.pathname;
  let foundRoute = null;

  //  const segments = location.pathname.split('/');
  // if (segments.length > 1) {
  //   routePath = segments[1];
  // }

  // Find the matching component route based on the routePath
  // const renderComponent = routePath
  //   ? props.componentRoutes.find((x) => x.id.toLowerCase() === routePath.toLowerCase())
  //   : '';

  let renderComponent = routePath
    ? props.componentRoutes.find((x) => {
        if (x.path.toLowerCase() === routePath.toLowerCase()) {
          foundRoute = x;
          return foundRoute;
        } else if (x.children && x.children.length > 0) {
          let childMenuRoute = x.children.find(
            (childItem) =>
              childItem.path.toLowerCase() === routePath.toLowerCase()
          );
          if (childMenuRoute) {
            foundRoute = childMenuRoute;
            return foundRoute;
          }
        }
        return foundRoute;
      })
    : "";

  // let renderComponent = (routePath, componentRoutes) => {

  //   let foundRoute = null;

  //   componentRoutes.find((x) => {
  //     if (x.path.toLowerCase() === routePath.toLowerCase()) {
  //       foundRoute = x;
  //       return foundRoute;
  //     } else if (x.children && x.children.length > 0) {
  //       let childMenuRoute = x.children.find((childItem) => childItem.path.toLowerCase() === routePath.toLowerCase())
  //       if (childMenuRoute) {
  //         foundRoute = childMenuRoute;
  //         return foundRoute;
  //       }
  //     }
  //     return foundRoute;
  //   })
  // }

  // const foundRoute = renderComponent(routePath, props.componentRoutes);
  // const isAuthorize = foundRoute ? hasPermission(foundRoute?.securityKey) : null;

  const isAuthorize = hasPermission(foundRoute?.securityKey);
  // const isAuthorize = hasPermission(renderComponent?.securityKey);

  // debugger
  return (
    <>
      <HeaderBanner />
      <div className="main-page-layout">
        <Sidebar componentRoutes={props.componentRoutes} />
        <div div className={`middle-page-section`}>
          {/* 
        {isAuthorize?.noPermission ? <Unauthorize /> : (
            <div className="center-content-part">
              <Outlet />
            </div>
          )
        } 
        */}

          {isAuthorize && (isAuthorize.isView || isAuthorize.isEdit) ? (
            <div className="center-content-part">
              <Outlet />
            </div>
          ) : (
            <Unauthorize />
          )}
        </div>
      </div>
    </>
  );
};

export default Layout;
