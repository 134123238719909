import { createContext, useEffect, useMemo, useState } from "react";
import { getAuthProps } from "../../lib/authenticationLibrary";

const ServiceLocationContext = createContext();

const ServiceLocationProvider = ({ children }) => {

    const [selectedLocation, setSelectedLocation] = useState(null);

    const userDetails = getAuthProps();
    const loggedInUserServiceLocation = userDetails?.user?.lServiceLocationKey || 0;

    useEffect(() => {
        if (loggedInUserServiceLocation) {
            setSelectedLocation(loggedInUserServiceLocation);
        }
    }, [loggedInUserServiceLocation]);

    const value = useMemo(() => ({
        selectedLocation,
        setSelectedLocation,
    }), [selectedLocation, setSelectedLocation]);

    return (
        <ServiceLocationContext.Provider value={value}>
            {children}
        </ServiceLocationContext.Provider>
    );

}

export { ServiceLocationProvider, ServiceLocationContext }